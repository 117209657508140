import styled from "styled-components";
export const ManagerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f5f5f5;
  form {
    display: flex;
    flex-direction: column;

    input, textarea {
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        padding: 5px;
  }

    button{
        margin-top: 10px;
        border-radius: 5px;
        border: 1px solid #ffc107;
        padding: 5px;
        cursor: pointer;
        background-color: #ffc107;
        color: #212529;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0.5rem 1rem;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        user-select: none;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        &:hover {
            background-color: #ffd54f;
            border-color: #ffd54f;
            box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
        

    }



`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
`;
