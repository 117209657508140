import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  background-color: #f5f5f5;
  button {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
