import styled from "styled-components";

export const PaginationList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        display: flex;
        margin: 0 10px 10px 0px;
        img{
            width: 50px;
            height: 50px;
            object-fit: cover;
            transform: scale(1.1);
            border-radius: 10px;
            margin-bottom: 10px;
        }
        a {
            display: block;
            padding: 10px;
            text-decoration: none;
            color: #000;
           
        }
    }
`;
