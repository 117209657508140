import styled from "styled-components";

export const PrimaryButton = styled.button`
  background-color: #ffc107;
  border-color: #ffc107;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  color: #212529;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0.5rem 1rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  &:hover {
    background-color: #ffd54f;
    border-color: #ffd54f;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  }
`;

export const SecondaryButton = styled.button`
  background-color: #fff;
  border-color: #fff;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  color: #212529;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0.5rem 1rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;

  &:hover {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  }
`;
