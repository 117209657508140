import axios from "axios";
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { PrimaryButton, SecondaryButton } from "./styles/Button";
import { Header } from "./styles/Header";
import {
  FormContainer,
  ManagerContainer,
  FormItem,
} from "./styles/ManagerContainer.styled";
import { PaginationList } from "./styles/PaginationList.styled";

function Manager() {
  const { register, handleSubmit } = useForm();
  const [result, setResult] = useState("");
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Ekle");
  const [items, setItems] = useState([]);

  //submit edilirken önce upload daha sonrasında ekleme işlemi yapılacak.
  const onSubmit = async (data) => {
    setDisabled(true);
    setResult(data.input);

    await axios
      .post(
        "https://cryptopusnft.io/dogatarihi/addItems",
        {
          name: data.name,
          image: "https://dogatarihi.cryptopusnft.io/uploads/" + fileName,
          category_name: data.category_name,
          description: data.description,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response);
        setDisabled(false);
        Swal.fire({
          title: "Başarılı",
          text: "Ürün başarıyla eklendi",
          icon: "success",
          confirmButtonText: "Tamam",
        });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          title: "Hata",
          text: "Ürün eklenirken bir hata oluştu",
          icon: "error",
          confirmButtonText: "Tamam",
        });
      });
  };

  const onFileChange = (e) => {
    setImageUploading(true);
    setDisabled(true);
    setFileName(e.target.files[0].name);
    setFilePath(e.target.files[0].path);
    setFile(e.target.files[0]);
    uploadImage(e.target.files[0]);
  };

  const uploadImage = async (image) => {
    const formData = new FormData();
    formData.append("image", image);
    try {
      const res = await axios.post(
        "https://cryptopusnft.io/dogatarihi/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { imagePath, imageName, imageExtension } = res.data;
      setImageUploading(false);
      setDisabled(false);
      setFileName(imageName);
      setFilePath(imagePath);
    } catch (err) {
      console.log(err);
    }
  };

  const onTabChange = (tab) => {
    setSelectedTab(tab);
    console.log(tab);
  };

  const getItems = async () => {
    const res = await axios.get("https://cryptopusnft.io/dogatarihi/getItems");
    setItems(res.data.slice(-10));
    console.log(res);
  };


  useEffect(() => {
    getItems();
  }, []);


  return (
    <ManagerContainer>
      <Header>
        <PrimaryButton onClick={() => onTabChange("Ekle")}>Ekle</PrimaryButton>
        <PrimaryButton onClick={() => onTabChange("Güncelle")}>
          Güncelle
        </PrimaryButton>
        <SecondaryButton onClick={() => onTabChange("Sil")}>
          Sil
        </SecondaryButton>
        <PrimaryButton onClick={() => onTabChange("Liste")}>
          Liste
        </PrimaryButton>
      </Header>
      {selectedTab === "Ekle" && (
         <FormContainer>
         <h1>Ekleme Formu</h1>
         <form onSubmit={handleSubmit(onSubmit)}>
           <input type="text" {...register("name")} placeholder={"Adı: "} />
           <input
             type="text"
             {...register("category_name")}
             placeholder={"Kategori Adı: "}
           />
           <textarea {...register("description")} placeholder={"Açıklama: "} />
           <input
             type="file"
             {...register("image")}
             onChange={onFileChange}
             placeholder={"Görsel: "}
           />
 
           <button disabled={disabled} type="submit">
             {imageUploading ? "Görsel Yükleniyor" : "Ekle"}
           </button>
         </form>
        </FormContainer>
      )
      }

      {selectedTab === "Liste" && (
       <div>
          <h1>Son Eklenen 10 </h1>
          <PaginationList>
          
          {items.map((item) => (
            <li>
              <img src={item.image} alt="dogatarihi" />
              <a>{item.id} - {item.name}</a>
            </li>
          ))}
        </PaginationList>
       </div>
       
      )}
    </ManagerContainer>
  );
}

export default Manager;
